import React, { useState, useEffect, useRef } from 'react';
import '../css/reset.css';
import '../css/portfolio.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';

import { Autoplay, Navigation, EffectCoverflow, Scrollbar } from 'swiper/modules';

const Portfolio = () => {
    const [modalBg, setModalBg] = useState(false);
    const [modalContent, setModalContent] = useState(0);
    const [swiperKey, setSwiperKey] = useState(0);
    const swiperRef = useRef(null);

    const [VW, setVW] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
            if (varUA.indexOf('android') > -1) {
                //안드로이드
            } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
                //IOS
            } else {
                //아이폰, 안드로이드 외 모바일
                setSwiperKey(prevKey => prevKey + 1);
            }

            setVW(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const portfolioInner = document.getElementsByClassName('portfolio_inner')[0];
        var element = document.getElementsByClassName('swiper-scrollbar');
        var widthVal = String((648 / 800) * VW);

        if (VW >= 1440) {
            return;
        } else if (VW > 1000) {
            portfolioInner.style.width = '1000px';
        } else if (VW >= 768) {
            portfolioInner.style.width = '800px';
        } else if (VW < 768) {
            const setWidthWithDelay = () => {
                // setTimeout 함수를 사용하여 1초(1000 밀리초) 후에 실행
                setTimeout(() => {
                    if (portfolioInner) {
                        portfolioInner.style.width = '800px';
                    }
                }, 100); // 1000 밀리초 = 1초
            };

            portfolioInner.style.width = '799px';
            setWidthWithDelay();
        }
        element[1].style = 'width: ' + widthVal + 'px !important';
    }, [VW]);

    // useEffect(() => {
    //     const portfolioInner = document.getElementsByClassName('portfolio_inner')[0];
    //     var element = document.getElementsByClassName('swiper-scrollbar')
    //     var widthVal = String((648/800)*VW)

    //     if(VW >= 1440){
    //         return
    //     } else if(VW > 1000) {
    //         portfolioInner.style.width = '1000px'
    //     } else if(VW >= 768){
    //         portfolioInner.style.width = '800px'
    //         var widthVal = String((648/800)*VW)
    //     } else if(VW < 768){
    //         var widthVal = String((648/800)*VW)
    //         const setWidthWithDelay = () => {
    //             // setTimeout 함수를 사용하여 1초(1000 밀리초) 후에 실행
    //             setTimeout(() => {
    //               if (portfolioInner) {
    //                 portfolioInner.style.width = '800px';
    //               }
    //             }, 100); // 1000 밀리초 = 1초
    //         };

    //         portfolioInner.style.width = '799px';
    //         setWidthWithDelay();
    //     }
    //     element[1].style = 'width: '+widthVal+'px !important'
    // }, [])

    function portfolioDetail(e) {
        var idx = e.target.offsetParent.swiperSlideIndex;
        switch (idx) {
            case 0:
                setModalContent(1);
                break;
            case 1:
                setModalContent(2);
                break;
            case 2:
                setModalContent(3);
                break;
            case 3:
                setModalContent(4);
                break;
            case 4:
                setModalContent(5);
                break;
            case 5:
                setModalContent(6);
                break;
            case 6:
                setModalContent(7);
                break;
            case 7:
                setModalContent(8);
                break;
        }

        setModalBg(true);
    }

    const portfolioDiv1 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>허브블록 제어 모바일 앱</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type1'>
                        <span className='portfolio_content_img1'></span>
                        <span className='portfolio_content_img2'></span>
                        <span className='portfolio_content_img3'></span>
                        <span className='portfolio_content_img4'></span>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>React Native 기반의 앱 개발</li>
                                <li>블루투스 4.0 loT 실시간통신</li>
                                <li>저전력 블루투스 BLE 호환</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.07 ~ 2023.08</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native</li>
                                <li>Bluetooth BLE</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv2 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>재고관리 솔루션 개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type2'>
                        <span className='portfolio_content_img1'></span>
                        <span className='portfolio_content_img2'></span>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>실시간 재고관리 솔루션 개발 (웹기반)</li>
                                <li>간편하고 쉬운 UI/UX</li>
                                <li>항목별 유통기한 관리</li>
                                <li>고객 맞춤형 솔루션</li>
                                <li>편리한 대시보드</li>
                                <li>RESTful API 개발</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2022.10 ~ 2023.01</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, RESTful API, Django, NodeJs, MySQL</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv3 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>경진대회 수상_라이프 로그 데이터 기반 치매 예측 Project</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type3'>
                        <span className='portfolio_content_img1'></span>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>라이프 로그 데이터 기반 치매 예측 Project</li>
                                <li>과기부에서 진행한 비공개 대회의 수치해석 분야 Task에서 2등 수상</li>
                                <li>웨어러블 디바이스를 통한 활동 및 수면 상태의 라이프 로그 데이터를 축적함</li>
                                <li>위의 데이터를 기반으로 치매 여부 예측 (정상, 경증 치매, 중증 치매)</li>
                                <li>Pytorch와 LighGBM 기반의 치매 예측 Model 생성</li>
                                <li>모델의 Ensemble을 통한 정확도 상승</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2021.07 ~ 2021.08</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Deep Learning, Machine Learning</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv4 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>최적발주량</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type4'>
                        <span className='portfolio_content_img1'></span>
                        <strong>일 평균 출고</strong>
                        <span className='portfolio_content_img2'></span>
                        <strong>최적발주량 시뮬레이션</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>산업데이터를 사용하여 재고관리에 필요한 알고리즘 개발</li>
                                <li>Raw Data 가공 및 전처리</li>
                                <li>일평균출고, 출고빈도수 등 출고패턴 파악</li>
                                <li>
                                    리드타임, 출고대응일수 등 각종 재고관리 지식을 사용하여 출고에 따른 최적발주량 계산
                                </li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2021.03 ~ 2021.07</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, RESTful API, Django, NodeJs, MySQL</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv5 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>Datastudio 리포팅</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type5'>
                        <span className='portfolio_content_img1'></span>
                        <span className='portfolio_content_img2'></span>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>분석한 데이터를 토대로 시각적 데이터로 변환하여 대시보드로 표현하는 프로젝트</li>
                                <li>선, 막대그래프, 파이차트, 표로 변환하여 나타냄</li>
                                <li>사용자가 선택하는 기능에 따라 실시간 동적표현 가능하게 만듦</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.08 ~ 2023.10</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Python, SQL, Matplotlib, Seaborn, Datastudio</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv6 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>솔루션서버 성능테스트</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type6'>
                        <span className='portfolio_content_img1'></span>
                        <strong>테스트 결과</strong>
                        <span className='portfolio_content_img2'></span>
                        <strong>응답시간 그래프</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>
                                    총 5개의 시나리오를 2개의 스레드로 30초간 로드밸런서에 지속적으로 요청(142시간
                                    테스트)
                                </li>
                                <li>총 요청당 32,148개의 요청을 처리, 평균 365ms의 반응속도</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.09 ~ 2023.09</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>AWS, Python, Jmeter</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv7 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>열처리 공정 불량 판별 AI모델 구축</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type7'>
                        <span className='portfolio_content_img1'></span>
                        <strong>열처리 생산공정 분류 모델</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>열처리 생산공정의 불량단계를 분류하는 모델구축</li>
                                <li>데이터 품질지수확인, 종속변수 전처리, 데이터 라벨링</li>
                                <li>
                                    시계열 데이터 처리, 결측치/이상치 처리, 상관관계, 다중공산성 처리, 변수의 중요도
                                    분석, Feature selection
                                </li>
                                <li>XGBoost 성능 최적화</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.10 ~ 2023.11</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Python, SQL, ARIMA, SARIMA, LSTM, XGBoost, 시계열분석, 머신러닝, 딥러닝</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv8 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div
                className='portfolio_modal_content'
                style={
                    VW >= 1440
                        ? {}
                        : VW >= 1000
                        ? {}
                        : VW >= 768
                        ? { width: '730px' }
                        : { width: 'calc(100VW - 50px)', height: '500px' }
                }
            >
                <div className='portfolio_modal_header'>
                    <h4>쇼핑몰 제품에 대한 고객의 긍·부정 리뷰 분석</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type8'>
                        <span className='portfolio_content_img1'></span>
                        <strong>쇼핑몰 제품에 대한 고객의 리뷰 분석</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>셀레니움 자동화 프로그램을 사용한 크롤링</li>
                                <li>각 브랜드 별 상위 5개의 아우터 제품 사용</li>
                                <li>최신 리뷰글 크롤링</li>
                                <li>Konlpy, Ltokenizer, MaxScoreTokenizer, Soynl 적용</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.11 ~ 2023.12</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Python, 셀레니움, 크롤링, NLP, RNN, Transformer</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const closeModal = () => {
        setModalBg(false);
        setModalContent(0);
        document.body.style.overflowY = 'auto';
    };

    return (
        <>
            <section className='portfolio_wrap' id='portfolioSection' name='portfolioSection'>
                <div className='portfolio_inner'>
                    <div className='portfolio_title_wrap'>
                        <h3>PORTFOLIO</h3>
                        <strong>데이터파이브 포트폴리오</strong>
                    </div>
                    <div className='portfolio_box_wrap'>
                        <div className='portfolio_scroll_wrap scroll-container'>
                            <Swiper
                                key={swiperKey}
                                ref={swiperRef}
                                onSwiper={swiper => {
                                    swiperRef.current = swiper;
                                }}
                                className='portfolio_box_slide'
                                spaceBetween={30}
                                slidesPerView={5}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1000: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 40,
                                    },
                                    1440: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 40,
                                    },
                                }}
                                direction={'horizontal'}
                                modules={[Autoplay, Navigation, EffectCoverflow, Scrollbar]}
                                loop={true}
                                init={true}
                                freeMode={false}
                                observe='true'
                                observeParents={false}
                                speed={3000}
                                scrollbar={{
                                    hide: false,
                                    draggable: false,
                                    dragSize: VW >= 768 ? 160 : 54,
                                }}
                                allowTouchMove={false}
                                autoplay={{
                                    delay: 0,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                            >
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_1'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <strong>허브블록 제어 모바일앱</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>
                                                            허브블록 제어
                                                            <br />
                                                            모바일앱
                                                        </strong>
                                                    </>
                                                )}
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            모바일 앱 구축
                                                            <br />
                                                            환경 교육용 개발 용역 성공적 수행
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn1'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_4'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <strong>재고관리 솔루션 개발</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>
                                                            재고관리
                                                            <br />
                                                            솔루션 개발
                                                        </strong>
                                                    </>
                                                )}
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            재고관리 솔루션 앱, 웹 구축
                                                            <br />
                                                            물류 현장 비즈니스 환경 개선 솔루션 개발
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn2'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_2'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>대회 수상</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <strong>경진대회 수상</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>
                                                            경진대회
                                                            <br />
                                                            수상
                                                        </strong>
                                                    </>
                                                )}
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            라이프 로그 데이터 기반 Project 은상
                                                            <br />
                                                            수치해석 분야 Task에서 2등 수상
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn3'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_3'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>데이터분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>데이터분석</strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            산업데이터를 사용하여 재고관리에 필요한
                                                            <br />
                                                            알고리즘 개발
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn4'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_5'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>데이터 분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <strong>Datastudio 리포팅</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>
                                                            Datastudio
                                                            <br />
                                                            리포팅
                                                        </strong>
                                                    </>
                                                )}
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            분석한 데이터를 토대로 시각적 데이터로
                                                            <br />
                                                            변환하여 대시보드로 표현하는 프로젝트 수행
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn5'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_6'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>솔루션개발</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>솔루션서버 성능테스트</strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            A 시나리오를 300개의 스레드로 30초간 로드
                                                            <br />
                                                            밸런서에 지속적으로 요청, 양호한 응답속도 구현
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn6'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_7'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>데이터 분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <strong>데이터분석</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>데이터분석</strong>
                                                    </>
                                                )}
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            열처리 생산공정의
                                                            <br />
                                                            불량단계를 분류하는 모델구축
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn7'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='portfolio_box portfolio_8'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>데이터 분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <strong>데이터분석</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        <strong>데이터분석</strong>
                                                    </>
                                                )}
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                {VW >= 768 ? (
                                                    <>
                                                        <span>
                                                            셀레니움 자동화 프로그램을
                                                            <br />
                                                            사용한 크롤링
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button onClick={portfolioDetail} className='portfolio_btn8'>
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    <div className={modalBg ? 'portfolio_modal_wrap' : 'blind'}>
                        {modalContent == 1 ? (
                            portfolioDiv1()
                        ) : modalContent == 2 ? (
                            portfolioDiv2()
                        ) : modalContent == 3 ? (
                            portfolioDiv3()
                        ) : modalContent == 4 ? (
                            portfolioDiv4()
                        ) : modalContent == 5 ? (
                            portfolioDiv5()
                        ) : modalContent == 6 ? (
                            portfolioDiv6()
                        ) : modalContent == 7 ? (
                            portfolioDiv7()
                        ) : modalContent == 8 ? (
                            portfolioDiv8()
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Portfolio;
